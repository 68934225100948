const { debounce, forEach, unset } = require('lodash');
const { gsap } = require('gsap/dist/gsap');
const { breakpoints } = require('site/components/functions');
const { alert } = require('site/components/alerts');
const Hammer = require('hammerjs');

let tiles = {};

function getTileId(target) {
    const $product = target.closest('.product');
    const $slickSlide = $product.closest('.slick-slide');
    let tileId = $product.data('cpid') || $product.data('pid');

    if ($slickSlide.length > 0) {
        tileId += `si${$slickSlide.index()}`;
    }

    return tileId;
}

function getTileElement(target) {
    return target.is('.product-tile') ? target : target.closest('.product-tile');
}

function regularAnimation(target) {
    const pid = getTileId(target);

    if (!tiles[pid].tl) {
        tiles[pid].tl = gsap.timeline();

        const nameHeight = $('a', tiles[pid].name).innerHeight();
        let nameOffset = 0;

        // if name has 1 line, then adds 2 more as offset
        if (nameHeight <= 30) {
            nameOffset = nameHeight * 2;
            // else if name has 2 lines, then add 1 more as offset
        } else if (nameHeight > 28 && nameHeight <= 60) {
            nameOffset = nameHeight / 2;
        }

        const innerOffset = 36;
        const heightInnerTile = tiles[pid].inner.innerHeight()
            + tiles[pid].desc.innerHeight() - (innerOffset + nameOffset);
        const bodyOffset = -60;
        const heightTile = heightInnerTile + tiles[pid].action.innerHeight();

        tiles[pid].tl
            .to(tiles[pid].inner, { height: heightInnerTile, duration: 0.5, ease: 'power1.inOut' })
            .to(tiles[pid].bg, { opacity: 1, duration: 0.5, ease: 'power1.inOut' }, 0)
            .to(tiles[pid].imgcontainer, {
                scale: 0.74,
                y: -27,
                duration: 0.5,
                ease: 'power1.inOut',
            }, 0)
            .to(tiles[pid].body, { y: bodyOffset, duration: 0.5, ease: 'power1.inOut' }, 0)
            .to(tiles[pid].desc, {
                y: -nameOffset,
                opacity: 1,
                duration: 0.25,
                ease: 'power1.inOut',
            }, 0.25)
            .to(target, {
                height: heightTile,
                y: -45,
                duration: 0.5,
                ease: 'power1.inOut',
            }, 0);
    } else {
        tiles[pid].tl.play();
    }

    target.addClass('animated');
}

function fullbleedAnimation(target) {
    const pid = getTileId(target);

    if (!pid || !tiles[pid]) {
        return;
    }

    if (!tiles[pid].tl) {
        tiles[pid].tl = gsap.timeline();

        tiles[pid].tl
            .to(tiles[pid].bg, { scale: 1.2, duration: 1, ease: 'power1.inOut' }, 0)
            .to(tiles[pid].imgcontainer, { scale: 1.05, duration: 1, ease: 'power1.inOut' }, 0)
            .to(tiles[pid].name, { y: 20, duration: 1, ease: 'power1.inOut' }, 0);
    } else {
        tiles[pid].tl
            .duration(1)
            .play();
    }

    target.addClass('animated');
}

function fullpictureAnimation(target) {
    const pid = getTileId(target);

    if (!tiles[pid].tl) {
        tiles[pid].tl = gsap.timeline();

        const heightTile = tiles[pid].inner.innerHeight() + tiles[pid].action.innerHeight();

        tiles[pid].tl
            .to(tiles[pid].img, { scale: 1.05, duration: 0.5, ease: 'power1.inOut' }, 0)
            .to(target, {
                height: heightTile,
                y: -25,
                duration: 0.5,
                ease: 'power1.inOut',
            }, 0);
    } else {
        tiles[pid].tl.play();
    }

    target.addClass('animated');
}

function reverseAnimation(target, duration) {
    const pid = getTileId(target);

    if (!tiles[pid] || !tiles[pid].tl) {
        return;
    }

    if (duration) {
        tiles[pid].tl
            .duration(duration)
            .reverse();
    } else {
        tiles[pid].tl.reverse();
    }

    target.removeClass('animated');
}

function resetTimelines() {
    forEach(tiles, (value, pid) => {
        if (!tiles[pid].tl) {
            return;
        }

        gsap.set(tiles[pid].inner, { clearProps: 'height' });
        gsap.set(tiles[pid].picture, { clearProps: 'all' });
        gsap.set(tiles[pid].bg, { clearProps: 'opacity' });
        gsap.set(tiles[pid].imgcontainer, { clearProps: 'transform' });
        gsap.set(tiles[pid].body, { clearProps: 'transform' });
        gsap.set(tiles[pid].desc, { clearProps: 'opacity' });
        gsap.set(tiles[pid].action, { clearProps: 'transform' });

        tiles[pid].tl.clear().kill();
        tiles[pid].target.removeClass('animated');
        unset(tiles[pid], 'tl');
    });
}

function openModal() {
    $(`#${$(this).data('modal-id')}`).modal('show');
}

function openRemoveSubscribeSaveProductModal() {
    // Get modal , product id and qty
    const $modal = $(this).closest('.modal');
    const productID = $modal.data('product-id');
    const pid = $modal.data('product-id');
    const quantity = $modal.find('.js-qty-number').val();
    $modal.modal('hide');
    // pass product id, qty to remove subscribe save product modal to add product with quantity
    const $modalToOpen = $(`#removeSubscribeSaveProductModal-${pid}`);
    $modalToOpen.attr('data-pid', pid);
    $modalToOpen.attr('data-quantity', quantity);
    $modalToOpen.modal('show');
}

function removeSubscribeSaveProducts() {
    const $modal = $(this).closest('.modal');
    // Get pid and quantity
    const pid = $modal.data('pid');
    const quantity = $modal.data('quantity');

    const form = {
        pid,
        quantity,
        isDeleteSubscribeAndSave: true
    };

    $.spinner().start();
    $.ajax({
        url: $(this).data('url'),
        method: 'POST',
        data: form,
        success: (data) => {
            $('.minicart').trigger('count:update', data);
            $('body').trigger('product:afterAddToCart', data);

            if (data.error && data.message) {
                alert({
                    context: 'alert-danger',
                    dismissible: true,
                    message: data.message,
                });
            } else {
                // subscription clean up
                window.localStorage.removeItem('subscriptionType');
                window.localStorage.setItem('subscribeAndSave', false);
                $.spinner().stop();
                // need to reload page so that subscription product remove modal will not show again.
                window.location.reload();
            }
            $.spinner().stop();
        },
        error: () => {
            $.spinner().stop();
        },
    });

    $modal.modal('hide');
}

function confirmSelection() {
    const $modal = $(this).closest('.modal');
    const pid = $modal.data('product-id');

    const isSubscriptionProduct = $(this).data('issubscriptionproduct');

    const form = {
        pid,
        quantity: $modal.find('.js-qty-number').val(),
    };

    if (isSubscriptionProduct) {
        form.isSubscribeAndSave = true;
    }

    $.spinner().start();
    $.ajax({
        url: $(this).data('url'),
        method: 'POST',
        data: form,
        success: (data) => {
            $('.minicart').trigger('count:update', data);
            $('body').trigger('product:afterAddToCart', data);

            if (data.error && data.message) {
                alert({
                    context: 'alert-danger',
                    dismissible: true,
                    message: data.message,
                });
            } else {
                // if one time order product added successfully to cart and subscription session is not active make subscription flag false and remove subscription type also. 
                if (!isSubscriptionProduct) {
                    window.localStorage.removeItem('subscriptionType');
                    window.localStorage.setItem('subscribeAndSave', false);
                }
            }

            $.spinner().stop();
        },
        error: () => {
            $.spinner().stop();
        },
    });

    $modal.modal('hide');
}

function handleAnimationVariation(target, callbacks) {
    const $product = target.closest('.product');

    if ($product.is('.product--fullbleed')) {
        callbacks.fullbleed();
    } else if ($product.is('.product--full-picture')) {
        callbacks.fullpicture();
    } else {
        callbacks.regular();
    }
}

function handleMouseEnter(e) {
    const $tile = getTileElement($(e.currentTarget));

    handleAnimationVariation($tile, {
        fullbleed: fullbleedAnimation.bind(null, $tile),
        fullpicture: fullpictureAnimation.bind(null, $tile),
        regular: regularAnimation.bind(null, $tile),
    });
}

function handleMouseLeave(e) {
    const $tile = getTileElement($(e.currentTarget));

    handleAnimationVariation($tile, {
        fullbleed: reverseAnimation.bind(null, $tile, 0.25),
        fullpicture: reverseAnimation.bind(null, $tile),
        regular: reverseAnimation.bind(null, $tile),
    });
}

function handleClickTouch(e) {
    const isSubscriptionProduct = $(this).hasClass('subscription-product');
    if (!isSubscriptionProduct) {
        e.preventDefault();
    }
}

function handleTapPress(e) {
    const $target = $(e.target);
    const $tile = getTileElement($target);
    const $animatedTile = $('.product-tile.animated');

    if ($target.is('.js-add-to-cart')) {
        return;
    }

    if ($tile.hasClass('animated')) {
        if (e.type === 'tap') {
            document.location.href = $tile.find('.product-tile__link').attr('href');
        } else {
            handleAnimationVariation($tile, {
                fullbleed: reverseAnimation.bind(null, $tile, 0.25),
                fullpicture: reverseAnimation.bind(null, $tile),
                regular: reverseAnimation.bind(null, $tile),
            });
        }
    } else {
        handleAnimationVariation($tile, {
            fullbleed: () => {
                reverseAnimation($animatedTile, 0.25);
                fullbleedAnimation($tile);
                setTimeout(() => {
                    document.location.href = $tile.find('.product-tile__link').attr('href');
                }, 1000);
            },
            fullpicture: () => {
                reverseAnimation($animatedTile);
                fullpictureAnimation($tile);
            },
            regular: () => {
                reverseAnimation($animatedTile);
                regularAnimation($tile);
            },
        });
    }
}

function unbindEvents() {
    $(document).off('click.anim touchend.anim')
        .off('mouseenter.playanim')
        .off('mouseleave.reverseanim');

    forEach(tiles, (value, pid) => {
        if (tiles[pid].hammer) {
            tiles[pid].hammer.off('tap press');
        }
    });
}

function getProductTiles() {
    const productTiles = document.getElementsByClassName('product-tile');

    tiles = {};
    for (let i = 0; i < productTiles.length; i++) {
        const $tile = $(productTiles[i]);
        const pid = getTileId($tile);

        if ($tile.is('.js-no-anim')) {
            return;
        }

        tiles[pid] = {
            target: $tile,
            links: $tile.find('.product-tile__link'),
            inner: $tile.find('.product-tile__inner'),
            picture: $tile.find('.product-tile__picture'),
            bg: $tile.find('.product-tile__bg'),
            imgcontainer: $tile.find('.product-tile__image-container'),
            img: $tile.find('.product-tile__image'),
            body: $tile.find('.product-tile__body'),
            desc: $tile.find('.product-tile__description'),
            action: $tile.find('.product-tile__action'),
            name: $tile.find('.product-tile__name'),
        };
    }
}

function handleWindowResize() {
    unbindEvents();
    getProductTiles();

    if (window.innerWidth > breakpoints.lg) {
        $(document).on('mouseenter.playanim', '.product-tile:not(.js-no-anim)', handleMouseEnter)
            .on('mouseleave.reverseanim', '.product-tile:not(.js-no-anim)', handleMouseLeave);
    } else {
        $(document).on('click.anim', '.product-tile__link:not(.js-no-anim)', handleClickTouch);

        forEach(tiles, (value, pid) => {
            tiles[pid].hammer = new Hammer(tiles[pid].target[0]);
            tiles[pid].hammer.on('tap press', handleTapPress);
        });
    }

    resetTimelines();
}

function bindEvents() {
    handleWindowResize();
    $(document).off('click', '.js-confirm-selection').off('click', '.js-add-to-cart');

    $(document).on('click', '.js-confirm-selection', confirmSelection)
        .on('click', '.js-add-to-cart', openModal)
        .on('click', '.subscribe-save-delete-confirmation-btn', removeSubscribeSaveProducts)
        .on('click', '.subscribe-save-product-remove-modal', openRemoveSubscribeSaveProductModal);

    $(window).on('resize orientationchange', debounce(handleWindowResize, 100));
}

module.exports = {
    init: () => {
        getProductTiles();
        bindEvents();
    },
    destroy: () => {
        getProductTiles();
        resetTimelines();
    },
};
